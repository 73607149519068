import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const MinSide = () => (
    <Layout>
        <SEO title='Minside' />
        <h1>Min side</h1>
        <p></p>
    </Layout>
)

export default MinSide